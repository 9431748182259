// import { Badge, Space, Switch, Table, Typography } from "antd";
import { Badge, Typography, Space, type TableColumnsType, Table, ConfigProvider, Select, Spin, Alert, Button } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import CheckCircle from "@ant-design/icons/CheckCircleFilled";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import WarningOutlined from "@ant-design/icons/WarningOutlined";

import TimeAgo from "react-timeago";
//@ts-ignore
import deStrings from "react-timeago/lib/language-strings/de";
//@ts-ignore
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { sequence$, setGroupAttrs, setSearchValue, signOn } from "./core";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { DataType } from "./core/d";
import SearchBox from "./SearchBox";
import { SearchOutlined } from '@ant-design/icons';
import { TeamsFxContext } from "../Context";
import { useData } from "@microsoft/teamsfx-react";
import config from "../lib/config";

const { Text } = Typography;
const formatter = buildFormatter(deStrings);

const getRecordStates = (record: DataType) => {
  const rs = [0, 0, 0, 0]
  if (record.children?.length) {
    const rsAry = record.children.map(getRecordStates)
    rsAry.reduce((p: number[], c: number[]) => {
      for (let i = 0; i < c.length; i++) {
        p[i] = p[i] + c[i]
      }
      return p
    }, rs)
  } else {
    if (record.status > -1) {
      rs[record.status % 4] = rs[record.status % 4] + 1
    }
  }
  return rs
}

const DeviceStatusIndicator: React.FC<{ record: DataType }> = ({ record }) => {
  const colors = ["gray", "green", "orange", "red"];
  const iconComponents = [
    QuestionCircleOutlined,
    CheckCircle,
    ExclamationCircleOutlined,
    WarningOutlined,
  ];
  const IconComponent = iconComponents[record.status % iconComponents.length];

  // @TODO: make recursive
  const childStates = getRecordStates(record)

  return (
    <Space>
      {record.status >= 0 ? (
        <Badge
          count={
            <IconComponent
              style={{ color: colors[record.status % colors.length] }}
            />
          }
        />
      ) : childStates.map((count, index) =>
        count > 0 ? (
          <Badge key={colors[index]} size="small" count={count} color={colors[index]} />
        ) : null
      )}
      {record.ts ? (
        <Text type="secondary">
          <TimeAgo date={record.ts} formatter={formatter} />
        </Text>
      ) : null}
    </Space>
  );
};

const DeviceStatusTable: React.FC<{ size: SizeType }> = ({ size }) => {
  const [data, setData] = useState<DataType[]>([]);
  const [checkByCustomer, setCheckByCustomer] = useState('');
  const [search, setSearch] = useState('')
  const inputRef = useRef<any>()
  const _size = size

  const colums: TableColumnsType<DataType> = [
    {
      title: <Select variant="borderless" size={_size} style={{ width: 150 }} onChange={setCheckByCustomer} defaultValue={''} options={[{ value: '', label: <strong>Device</strong> }, { value: ['kundennummer', 'raumbezeichnung',].join(','), label: <strong>Kundennummer</strong> }, { value: ['manufacturer'].join(','), label: <strong>Hersteller</strong> }]} />,
      dataIndex: "device",
      key: "device",
      render: (value, record, index) => (
        <div style={{ display: "inline-block", maxWidth: "90%" }}>
          <Text strong={Boolean(record.children?.length)}>{value}</Text>
          {record.description && (
            <div>
              <Text type="secondary" style={{ fontSize: "0.8em" }}>
                {record.description}
              </Text>
            </div>
          )}
        </div>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <SearchBox placeholder="SN, Gerätename, Kundennr." size='middle' onChange={setSearch} onClear={close} onClose={close} inputRef={inputRef} />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: search ? '#1677ff' : undefined }} />
      ),
      onFilter: (value, record) => true
      ,
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => inputRef.current?.select(), 100);
        }
      }
    },
    {
      title: <Text>Status</Text>,
      dataIndex: "status",
      key: "status",
      render: (value, record, index) => <DeviceStatusIndicator record={record} />,
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: () => <span>Delete</span>,
      width: 50,
      hidden: true,
    },
  ];



  /*** 
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  Add to Table: rowSelection={rowSelection}
  */

  const [needConsent, setNeedConsent] = useState(false);
  const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
  const { loading, data: authSucceed, error, reload } = useData(async () => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    if (needConsent) {
      await teamsUserCredential!.login(["User.Read"]);
      setNeedConsent(false);
    }
    try {
      const token = (await teamsUserCredential.getToken(""))!.token;
      const authSucceeded = await signOn(token)
      return authSucceeded
    } catch (error: any) {
      if (error.message.includes("The application may not be authorized.")) {
        setNeedConsent(true);
      } else {
        throw new Error("SignOn konnte nicht ausgeführt werden.")
      }
    }
  });

  useEffect(() => {
    // console.log(data)
    setGroupAttrs(checkByCustomer ? checkByCustomer.split(',') : [])
    setSearchValue(search ?? '')
    setData([])
    // @TODO: rename token to authSucceeded
    // debugger
    if (config.isDevEnv === false && !authSucceed) {
      return () => { }
    }
    // setToken(token)
    const subscription = sequence$.subscribe(setData);
    return () => subscription.unsubscribe();
  }, [checkByCustomer, search, authSucceed]);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#183146" } }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {config.isDevEnv === false && (error || needConsent) ? <Alert
            message="Authentifizierung fehlgeschlagen"
            showIcon
            description="Sie konnten nicht ordnungsgemäß angemeldet werden"
            type="error"
            action={
              <Button size="small" onClick={reload}>
                Noch einmal versuchen
              </Button>
            }
          /> :
            <Spin spinning={loading}>
              <Table
                size={_size}
                columns={colums}
                pagination={{ defaultPageSize: 20 }}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: undefined,
                  // rowExpandable: (record) => Boolean(record.children?.length),
                }}
                dataSource={data}
              />
            </Spin>}
        </Space>
    </ConfigProvider>
  );
};

export default DeviceStatusTable;
