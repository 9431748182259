import { useContext } from "react";
import { TeamsFxContext } from "./Context";

export interface TabProps  { 
  children: React.ReactNode
}

export default function Tab(props: TabProps) {
  const { themeString } = useContext(TeamsFxContext);
  
  return (
    <div
      className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}
    >
      <div style={{ padding: "1.5em 2em" }}>
        {props.children}
      </div>
    </div>
  );
}
